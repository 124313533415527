if (document.readyState !== 'loading') {
    handleTeaser();
} else {
    document.addEventListener('DOMContentLoaded', function () {
        handleTeaser();
    });
}

document.addEventListener('TeaserContentUpdated', function () {
    handleTeaser();
});

function handleTeaser() {
    // Span for hover effect
    const teaserActionLinks = document.querySelectorAll('.cmp-teaser__action-link');
    teaserActionLinks.forEach(function (teaserActionLink) {
        if (teaserActionLink.firstChild.nodeType === 3) {
            const span = document.createElement('span');
            while (teaserActionLink.firstChild) {
                span.appendChild(teaserActionLink.firstChild)
            }
            teaserActionLink.appendChild(span);
        }
    });

    const teasers = document.querySelectorAll('.teaser');
    const globalCursor = document.querySelector('.global-cursor');
    teasers.forEach(item => {
        // Add copyright to image
        const imageWrapper = item.querySelector('.cmp-image');
        const imageElement = item.querySelector('.cmp-image__image');

        if (imageElement) {
            const dataCopyright = imageElement.getAttribute('data-copyright');
            if (dataCopyright && dataCopyright.length > 0 && imageElement.querySelector('.cmp-image__copyright') === null) {
                const copyright = document.createElement('div');
                copyright.classList.add('cmp-image__copyright');
                copyright.innerHTML = dataCopyright;
                imageWrapper.appendChild(copyright);
            }
        }

        // Global cursor hover effect on image teasers
        if (item.classList.contains('teaser--image')) {
            const teaserBody = item.querySelector('.cmp-teaser');
            teaserBody.addEventListener('mouseover', () => {
                globalCursor.innerText = item.querySelectorAll('.cmp-teaser__action-link span')[0].innerText;
                globalCursor.classList.add('active');
            });
            teaserBody.addEventListener('mouseleave', () => {
                globalCursor.classList.remove('active');
            });
        }

        // Link list teaser
        if (item.classList.contains('teaser--link-list')) {
            const description = item.querySelector('.cmp-teaser__description');
            const pretitle = item.querySelector('.cmp-teaser__pretitle');
            const title = item.querySelector('.cmp-teaser__title');
            const links = item.querySelectorAll('.cmp-teaser__action-link');
            const content = item.querySelector('.cmp-teaser__content');
            const image = item.querySelector('.cmp-image__image');

            // front side
            if (description) {
                const descriptionContent = description.innerHTML;
                description.innerHTML = `<div class="cmp-teaser__description-in">${descriptionContent}</div>`;
            }

            // back side
            if (item.querySelector('.cmp-teaser__back-side') === null) {
                let backLinks = '';
                links.forEach(link => {
                    backLinks += `<li><a href="${link.href}" tabindex="-1"${link.target === '_blank' ? ' target="_blank"' : ''}>${link.innerHTML}</a></li>`;
                });

                let teaserBackSideContent = `
                <h2 class="cmp-teaser__back-title">${title.innerText}</h2>
                <ul class="cmp-teaser__back-action-links">${backLinks}</ul>
            `;

                if (pretitle) {
                    const pretitleContent = pretitle.innerHTML;
                    pretitle.setAttribute('tabindex', '0');
                    pretitle.innerHTML = `<div class="cmp-teaser__pretitle-in">${pretitleContent}</div>`;

                    teaserBackSideContent += `<div class="cmp-teaser__back-pretitle" tabindex="-1"><div class="cmp-teaser__back-pretitle-in">${pretitleContent}</div></div>`;
                }

                const teaserBackSide = document.createElement('div');
                teaserBackSide.classList.add('cmp-teaser__back-side');
                teaserBackSide.innerHTML = teaserBackSideContent;
                item.appendChild(teaserBackSide);

                const backPretitle = item.querySelector('.cmp-teaser__back-pretitle');
                const backActionLinks = item.querySelectorAll('.cmp-teaser__back-action-links a');

                content.addEventListener('click', () => {
                    item.classList.add('active');
                    changeLinksTabIndex(backActionLinks, '0');
                    backPretitle.setAttribute('tabindex', '0');
                });

                if (image) {
                    image.addEventListener('click', () => {
                        item.classList.add('active');
                        changeLinksTabIndex(backActionLinks, '0');
                        backPretitle.setAttribute('tabindex', '0');
                    });
                }

                if (pretitle) {
                    pretitle.addEventListener('click', () => {
                        item.classList.add('active');
                        changeLinksTabIndex(backActionLinks, '0');
                        backPretitle.setAttribute('tabindex', '0');
                    });

                    pretitle.addEventListener('keydown', (e) => {
                        if (e.keyCode === 13) {
                            item.classList.add('active');
                            changeLinksTabIndex(backActionLinks, '0');
                            backPretitle.setAttribute('tabindex', '0');
                        }
                    });

                    backPretitle.addEventListener('click', () => {
                        item.classList.remove('active');
                        changeLinksTabIndex(backActionLinks, '-1');
                        backPretitle.setAttribute('tabindex', '-1');
                    });

                    backPretitle.addEventListener('keydown', (e) => {
                        if (e.keyCode === 13) {
                            item.classList.remove('active');
                            changeLinksTabIndex(backActionLinks, '-1');
                            backPretitle.setAttribute('tabindex', '-1');
                        }
                    });
                }
            }

            function changeLinksTabIndex(links, tabIndex) {
                links.forEach(link => {
                    link.setAttribute('tabindex', tabIndex);
                });
            }
        }

        // Stage teaser
        const isAuthorMode = document.getElementById('meta-wcmmode'); // Hotfix for AEM viewport height bug

        if (item.classList.contains('teaser--stage') && !isAuthorMode) {
            setStageTeaserHeight();
            window.addEventListener('resize', () => setStageTeaserHeight());
        } else if (item.classList.contains('teaser--stage') && isAuthorMode) {
            setStageTeaserHeight(true);
            window.addEventListener('resize', () => {
                setStageTeaserHeight(true);
            });
        }

        function setStageTeaserHeight(isAuthorMode = false) {
            const header = document.querySelector('.cmp-header');
            const teaser = item.querySelector('.cmp-teaser');

            if (isAuthorMode && teaser) {
                teaser.style.height = '600px';
            } else if (header && teaser) {
                const teaserHeight = document.documentElement.clientHeight - header.clientHeight;
                teaser.style.height = `${teaserHeight}px`;
            }
        }
    });
}
