import Swiper, { Navigation, Pagination, Autoplay, EffectCreative } from "swiper";
import smoothscroll from 'smoothscroll-polyfill';

Swiper.use([Navigation, Pagination, Autoplay, EffectCreative ]);
smoothscroll.polyfill();

const handleMouseInAndOut = (carouselPrev, carouselNext, carouselWrapper) => {
    carouselPrev.style.display = 'none';
    carouselNext.style.display = 'none';
    carouselWrapper.addEventListener('mousemove', (e) => {
        const wrapperPosY = carouselWrapper.getBoundingClientRect().top;
        if (carouselWrapper.clientHeight - 224 > e.clientY - wrapperPosY) {
            if (e.clientX > window.innerWidth / 2) {
                carouselNext.style.display = 'block';
                carouselNext.style.top = e.clientY - wrapperPosY - 37 + 'px';
                carouselNext.style.left = e.clientX - 37 + 'px';
                carouselPrev.style.display = 'none';
            } else {
                carouselPrev.style.display = 'block';
                carouselPrev.style.top = e.clientY - wrapperPosY - 37 + 'px';
                carouselPrev.style.left = e.clientX - 37 + 'px';
                carouselNext.style.display = 'none';
            }
        } else {
            carouselPrev.style.display = 'none';
            carouselNext.style.display = 'none';
        }
    });
    carouselWrapper.addEventListener('mouseleave', () => {
        carouselPrev.style.display = 'none';
        carouselNext.style.display = 'none';
    });
}

if (document.readyState !== 'loading') {
    handleCarousel();
} else {
    document.addEventListener('DOMContentLoaded', function () {
        handleCarousel();
    });
}

document.addEventListener('CarouselContentUpdated', function () {
    handleCarousel();
});

document.addEventListener('ImageContentUpdated', function () {
    // Fix for carousel gallery and loading image caption
    handleCarousel();
});

function handleCarousel() {
    const carouselWrappers = document.querySelectorAll('.carousel');

    carouselWrappers.forEach(function (carouselWrapper) {
        const carousel = carouselWrapper.querySelector('.cmp-carousel');
        const carouselContent = carousel.querySelector('.cmp-carousel__content');
        const carouselItems = carousel.querySelectorAll('.cmp-carousel__item');
        const carouselActions = carousel.querySelector('.cmp-carousel__actions');
        const carouselIndicators = carousel.querySelector('.cmp-carousel__indicators');

        carousel.classList.add('swiper');
        carouselContent.classList.add('swiper-wrapper');
        carouselItems.forEach(function (carouselItem) {
            carouselItem.classList.add('swiper-slide');
        });

        if (carouselActions) {
            carouselActions.remove();
        }

        if (carouselIndicators) {
            carouselIndicators.remove();
        }

        const carouselPrev = document.createElement('button');
        carouselPrev.classList.add('cmp-carousel__prev');
        carouselPrev.classList.add('swiper-button-prev');
        carouselPrev.innerHTML = 'Vorheriges';
        carouselPrev.setAttribute('type', 'button');
        const carouselNext = document.createElement('button');
        carouselNext.classList.add('cmp-carousel__next');
        carouselNext.classList.add('swiper-button-next');
        carouselNext.innerHTML = 'Nächstes';
        carouselNext.setAttribute('type', 'button');
        carousel.appendChild(carouselPrev);
        carousel.appendChild(carouselNext);

        const carouselPagination = document.createElement('div');
        carouselPagination.classList.add('cmp-carousel__pagination');
        carouselPagination.classList.add('swiper-pagination');
        carousel.appendChild(carouselPagination);

        if (carouselWrapper.classList.contains('carousel--testimonial')) {
            handleMouseInAndOut(carouselPrev, carouselNext, carouselWrapper);
            const testimonialSwiper = new Swiper(carousel, {
                slidesPerView: 1,
                spaceBetween: 130,
                navigation: {
                    nextEl: carouselNext,
                    prevEl: carouselPrev,
                },
                pagination: {
                    el: carouselPagination,
                    clickable: true,
                    type: 'bullets',
                    renderBullet: (index, className) => {
                        const actual = index > 8 ? index + 1 : `0${index + 1}`;
                        const total = carouselItems.length > 9 ? carouselItems.length : `0${carouselItems.length}`;
                        return `<span class="${className} cmp-carousel__pagination-item">
                                    <span class="cmp-carousel__pagination-item-actual">${actual}</span>
                                    <span class="cmp-carousel__pagination-item-total">${total}</span>
                                </span>`;
                    }
                },
            });
        } else if (carouselWrapper.classList.contains('carousel--gallery')) {
            const isAuthorMode = document.getElementById('meta-wcmmode'); // Hotfix for AEM viewport height bug
            const carouselInWrapper = carouselWrapper.querySelector('.cmp-carousel');

            const existingCarouselInfo = carouselInWrapper.querySelector('.cmp-carousel__info');
            if (existingCarouselInfo) {
                existingCarouselInfo.remove();
            }

            const carouselInfo = document.createElement('div');
            const firstImage = carouselItems[0].querySelector('img');
            const firstImageCaption = firstImage.getAttribute('data-caption');
            const firstImageDescription = firstImage.getAttribute('data-description');
            carouselInfo.classList.add('cmp-carousel__info');
            carouselInfo.innerHTML = `<div class="cmp-carousel__info-caption">${firstImageCaption ? firstImageCaption : ''}</div>
                                      <div class="cmp-carousel__info-caption is-opacity"></div>
                                       <div class="cmp-carousel__info-description">
                                           ${firstImageDescription ? firstImageDescription : ''}
                                           <div class="cmp-carousel__info-index">01</div>
                                       </div>`;
            carouselInWrapper.appendChild(carouselInfo);

            if (!isAuthorMode) {
                setGalleryHeight();
                window.addEventListener('resize', setGalleryHeight);
            } else {
                carouselInWrapper.classList.add('cmp-carousel--author-mode');
            }

            function setGalleryHeight() {
                carouselInWrapper.style.height = `${window.innerHeight}px`;
            }

            carouselItems.forEach(function (carouselItem) {
                const imageWrapper = carouselItem.querySelector('.cmp-image');

                if (imageWrapper) {
                    const image = imageWrapper.querySelector('img');

                    const imageContainer = document.createElement('div');
                    imageContainer.classList.add('cmp-image__container');
                    imageWrapper.appendChild(imageContainer);

                    if (image) {
                        imageContainer.appendChild(image);
                    }
                }
            });

            const gallerySwiper = new Swiper(carousel, {
                slidesPerView: 1,
                spaceBetween: 0,
                navigation: {
                    nextEl: carouselNext,
                    prevEl: carouselPrev,
                },
                pagination: {
                    el: carouselPagination,
                    clickable: true,
                    type: 'bullets',
                    renderBullet: (index, className) => {
                        const actual = index > 8 ? index + 1 : `0${index + 1}`;
                        return `<span class="${className} cmp-carousel__pagination-item">
                                    <span class="cmp-carousel__pagination-item-actual">${actual}</span>
                                </span>`;
                    }
                },
                effect: "creative",
                creativeEffect: {
                    prev: {
                        shadow: true,
                        translate: ["-20%", 0, -1],
                    },
                    next: {
                        translate: ["100%", 0, 0],
                    },
                },
                speed: 800,
                on: {
                    slideChange: function () {
                        const currentSlide = this.slides[this.activeIndex];
                        const currentImage = currentSlide.querySelector('img');
                        const actualIndex = this.activeIndex > 8 ? this.activeIndex + 1 : `0${this.activeIndex + 1}`
                        const captionElements = carouselInfo.querySelectorAll('.cmp-carousel__info-caption');
                        const descriptionElement = carouselInfo.querySelector('.cmp-carousel__info-description');
                        const viewportWidth = window.innerWidth;

                        if (currentImage) {
                            const currentImageCaption = currentImage.getAttribute('data-caption');

                            captionElements.forEach(function (captionElement) {
                                if (viewportWidth < 768) {
                                    captionElement.innerText = currentImageCaption;
                                } else {
                                    if (captionElement.classList.contains('is-opacity')) {
                                        captionElement.innerText = currentImageCaption;
                                        setTimeout(function () {
                                            captionElement.classList.remove('is-opacity');
                                        }, 100);
                                    } else {
                                        setTimeout(function () {
                                            captionElement.classList.add('is-opacity');
                                        }, 100);
                                    }
                                }
                            });
                        }

                        if (currentImage && descriptionElement) {
                            const currentImageDescription = currentImage.getAttribute('data-description');
                            descriptionElement.innerHTML = `${currentImageDescription ? currentImageDescription : ''}<div class="cmp-carousel__info-index">${actualIndex}</div>`;
                        } else if (!currentImage && descriptionElement) {
                            descriptionElement.innerHTML = `<div class="cmp-carousel__info-index">${actualIndex}</div>`;
                        }
                    }
                }
            });
        } else if (carouselWrapper.classList.contains('carousel--facts-figures')) {
            const factsFiguresSwiper = new Swiper(carousel, {
                slidesPerView: 1,
                spaceBetween: 0,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                },
                pagination: {
                    el: carouselPagination,
                    clickable: true,
                    type: 'bullets',
                    renderBullet: (index, className) => {
                        const currentTeaser = carouselItems[index].querySelector('.cmp-teaser__title');
                        const currentTeaserTitle = currentTeaser.innerText;
                        const actual = index > 8 ? index + 1 : `0${index + 1}`;
                        return `<span class="${className} cmp-carousel__pagination-item">
                                    <span class="cmp-carousel__pagination-line-horizontal"></span>
                                    <span class="cmp-carousel__pagination-line-vertical"></span>
                                    ${currentTeaserTitle ? `<span class="cmp-carousel__pagination-item-title">${actual}<span>${currentTeaserTitle}</span></span>` : ''}
                                </span>`;
                    }
                },
                on: {
                    autoplayTimeLeft(s, time, progress) {
                        const activeBulletSpanVertical = carousel.querySelector('.swiper-pagination-bullet-active .cmp-carousel__pagination-line-vertical');
                        const activeBulletSpanHorizontal = carousel.querySelector('.swiper-pagination-bullet-active .cmp-carousel__pagination-line-horizontal');
                        activeBulletSpanVertical.style.setProperty("height", `${(1 - progress) * 100}%`);
                        activeBulletSpanHorizontal.style.setProperty("width", `${(1 - progress) * 100}%`);

                    },
                    slideChange() {
                        removePaginationAnimation(carousel);
                    }
                }
            });

            const paginationItems = carousel.querySelectorAll('.cmp-carousel__pagination-item');
            paginationItems[0].classList.add('cmp-carousel__pagination-item--active');
        } else {
            // Stage swiper
            const pauseButton = document.createElement('button');
            pauseButton.classList.add('cmp-carousel__pause');
            pauseButton.classList.add('swiper-button-pause');
            pauseButton.innerHTML = '<svg viewBox="0 0 48 48"><circle cx="24" cy="24" r="20"></circle></svg><span></span>';
            pauseButton.setAttribute('type', 'button');
            carousel.appendChild(pauseButton);
            const pauseButtonCircle = pauseButton.querySelector('svg');

            const scrollDownButton = document.createElement('button');
            scrollDownButton.classList.add('cmp-carousel__scroll-down');
            scrollDownButton.setAttribute('type', 'button');
            carousel.appendChild(scrollDownButton);

            let isPaused = false;

            const stageSwiper = new Swiper(carousel, {
                slidesPerView: 1,
                spaceBetween: 0,
                autoHeight: true,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                },
                pagination: {
                    el: carouselPagination,
                    clickable: true,
                    type: 'bullets',
                    renderBullet: (index, className) => {
                        return `<span class="${className} cmp-carousel__pagination-item">
                                    <span class="cmp-carousel__pagination-line-horizontal"></span>
                                </span>`;
                    }
                },
                on: {
                    autoplayTimeLeft(s, time, progress) {
                        const activeBulletSpanHorizontal = carousel.querySelector('.swiper-pagination-bullet-active .cmp-carousel__pagination-line-horizontal');
                        pauseButtonCircle.style.setProperty("--progress", -progress);
                        activeBulletSpanHorizontal.style.setProperty("width", `${(1 - progress) * 100}%`);
                    },
                    slideChange() {
                        removePaginationAnimation(carousel);
                        pauseButton.classList.remove('cmp-carousel__pause--paused');
                        isPaused = false;
                    }
                }
            });

            const paginationItems = carousel.querySelectorAll('.cmp-carousel__pagination-item');
            paginationItems[0].classList.add('cmp-carousel__pagination-item--active');

            pauseButton.addEventListener('click', function () {
                if (isPaused) {
                    isPaused = playCarousel(stageSwiper, pauseButton);
                } else {
                    isPaused = pauseCarousel(stageSwiper, pauseButton);
                }
            });

            scrollDownButton.addEventListener('click', function () {
                const carouselHeight = carousel.offsetHeight;
                const carouselBottom = carousel.offsetTop + carouselHeight;
                window.scrollTo({
                    top: carouselBottom,
                    behavior: 'smooth'
                });
            });

            // Auto height for images in stage carousel
            carouselItems.forEach(function(item) {
                const image = item.querySelector(':scope > .image'); // Selects direct child images only
                const header = document.querySelector('.cmp-header');
                const isAuthorMode = document.getElementById('meta-wcmmode'); // Hotfix for AEM viewport height bug

                if (isAuthorMode && image) {
                    image.style.height = '600px';
                    image.classList.add('image--stage');
                } else if (header && image) {
                    const imageHeight = document.documentElement.clientHeight - header.clientHeight;
                    image.style.height = `${imageHeight}px`;
                    image.classList.add('image--stage');
                }
            });
        }

        function removePaginationAnimation(currentCarousel) {
            const bullets = currentCarousel.querySelectorAll('.cmp-carousel__pagination-item');
            bullets.forEach(function (bullet) {
                const paginationLineHorizontal = bullet.querySelector('.cmp-carousel__pagination-line-horizontal');
                const paginationLineVertical = bullet.querySelector('.cmp-carousel__pagination-line-vertical');

                if (paginationLineHorizontal) {
                    paginationLineHorizontal.style.setProperty("width", `0`);
                }
                if (paginationLineVertical) {
                    paginationLineVertical.style.setProperty("height", `0`);
                }
            });
        }

        function pauseCarousel(currentSwiper, currentPauseButton) {
            currentSwiper.autoplay.pause();
            currentPauseButton.classList.add('cmp-carousel__pause--paused');
            return true;
        }

        function playCarousel(currentSwiper, currentPauseButton) {
            currentSwiper.autoplay.resume();
            currentPauseButton.classList.remove('cmp-carousel__pause--paused');
            return false;
        }
    });
}
