if (document.readyState !== 'loading') {
    handleCursor();
} else {
    document.addEventListener('DOMContentLoaded', function () {
        handleCursor();
    });
}

function handleCursor() {
    const globalCursor = document.createElement('div');
    globalCursor.classList.add('global-cursor');
    document.body.appendChild(globalCursor);

    document.addEventListener('mousemove', function(e){
        const x = e.clientX;
        const y = e.clientY;
        globalCursor.style.left = x + 'px';
        globalCursor.style.top = y + 'px';
    });

    document.addEventListener('mousedown', function() {
        globalCursor.classList.add('pressed');
    });

    document.addEventListener('mouseup', function() {
        globalCursor.classList.remove('pressed');
    });
}
