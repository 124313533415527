if (document.readyState !== 'loading') {
    handleImage();
} else {
    document.addEventListener('DOMContentLoaded', function () {
        handleImage();
    });
}

document.addEventListener('ImageContentUpdated', function () {
    handleImage();
});

function handleImage() {
    const images = document.querySelectorAll('.image');

    // Add copyright to image
    images.forEach(function (image) {
        const imageWrapper = image.querySelector('.cmp-image');
        const imageElement = image.querySelector('.cmp-image__image');

        if (imageWrapper || imageElement) {
            if (imageElement) {
                const dataCopyright = imageElement.getAttribute('data-copyright');
                if (dataCopyright && dataCopyright.length > 0 && image.querySelector('.cmp-image__copyright') === null) {
                    const copyright = document.createElement('div');
                    copyright.classList.add('cmp-image__copyright');
                    copyright.innerHTML = dataCopyright;
                    imageWrapper.appendChild(copyright);
                }
            }
        }
    });
}
