if (document.readyState !== 'loading') {
    handlePartnerFragment();
} else {
    document.addEventListener('DOMContentLoaded', function () {
        handlePartnerFragment();
    });
}

document.addEventListener('PartnerContentUpdated', function () {
    handlePartnerFragment();
});

function handlePartnerFragment() {
    const partnerFragments = document.querySelectorAll('.partner-fragment');

    partnerFragments.forEach(function (partnerFragment) {
        const imageBlocks = partnerFragment.querySelectorAll('.cmp-image');

        imageBlocks.forEach(function (imageBlock) {
            const metaCaptionElement = imageBlock.querySelector('meta[itemprop="caption"]');
            const linkElement = imageBlock.querySelector('.cmp-image__link');

            let imageCaption, imageLink;
            if (metaCaptionElement) {
                imageCaption = metaCaptionElement.getAttribute('content');
            }
            if (linkElement) {
                imageLink = linkElement.getAttribute('href');
            }

            if (imageBlock.querySelector('.cmp-image__caption') === null && imageCaption && imageLink) {
                const caption = document.createElement('h3');
                caption.classList.add('cmp-image__caption');
                caption.innerHTML = `<a href="${imageLink}">${imageCaption}</a>`;
                imageBlock.appendChild(caption);
            }
        });
    });
}
