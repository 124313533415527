if (document.readyState !== 'loading') {
    handleSocialSharing();
} else {
    document.addEventListener('DOMContentLoaded', function () {
        handleSocialSharing();
    });
}

document.addEventListener('SocialSharingContentUpdated', function () {
    handleSocialSharing();
});

function handleSocialSharing() {
    const socialWrappers = document.querySelectorAll('.social-sharing');
    const socialTitle = "Artikel teilen";

    const getSocialEndpoint = async (url) => {
        const metaTitle = encodeURIComponent(document.querySelector('meta[property="og:title"]').getAttribute('content'));
        const currentUrl = encodeURIComponent(window.location.href);
        const options = {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                "Content-Type": "application/json",
            }
        }
        let results = [];

        await fetch(url, options)
            .then(response => response.json())
            .then(data => {
                results = data.data.socialSharingListByPath.item.socialSharingLinks;
            });

        if (results.length > 0 && metaTitle && currentUrl) {
            results.forEach((socialItem) => {
                if (socialItem.link) {
                    socialItem.link = socialItem.link.replace('<url>', currentUrl).replace('<og-title>', metaTitle);
                }
            });
        }

        return results;
    }

    socialWrappers.forEach(function (socialWrapper) {
        const isHorizontal = socialWrapper.classList.contains('social-sharing--horizontal');
        let socialEndpoint;
        if (socialWrapper.dataset.endpoint) {
            socialEndpoint = socialWrapper.dataset.endpoint;
        } else {
            const firstChild = socialWrapper.firstElementChild;
            socialEndpoint = firstChild.getAttribute('data-endpoint');
        }

        getSocialEndpoint(socialEndpoint).then(function (result) {
           if (isHorizontal) {
               let socialHtml = `<div class="cmp-social-sharing cmp-social-sharing--is-horizontal">
                                    <ul class="cmp-social-sharing__list">`;
               result.forEach(function (socialItem) {
                   socialHtml += `<li class="cmp-social-sharing__item">
                                   <a href="${socialItem.link}" class="cmp-social-sharing__link" target="_blank" rel="noopener">
                                       <span class="cmp-social-sharing__icon">
                                           <img src="${socialItem.logo._path}" alt="${socialItem.label}">
                                       </span> 
                                   </a>
                               </li>`;
               });
               socialHtml += `</ul></div>`;
               socialWrapper.innerHTML = socialHtml;
           } else {
               let socialHtml = `<div class="cmp-social-sharing">
                                <button type="button" class="cmp-social-sharing__button">${socialTitle}</button>
                                <div class="cmp-social-sharing__overlay">
                                    <h3 class="cmp-social-sharing__title">${socialTitle}</h3>
                                    <ul class="cmp-social-sharing__list">`;
               result.forEach(function (socialItem) {
                   socialHtml += `<li class="cmp-social-sharing__item">
                                   <a href="${socialItem.link}" class="cmp-social-sharing__link" target="_blank" rel="noopener">
                                       <span class="cmp-social-sharing__icon">
                                           <img src="${socialItem.logo._path}" alt="${socialItem.label}">
                                       </span> 
                                       <span>${socialItem.label}</span>
                                   </a>
                               </li>`;
               });
               socialHtml += '</ul></div></div>';
               socialWrapper.innerHTML = socialHtml;

               const socialTitleButton = socialWrapper.querySelector('.cmp-social-sharing__button');
               const socialOverlay = socialTitleButton.nextElementSibling;
               socialTitleButton.addEventListener('click', function () {
                   socialOverlay.classList.toggle('cmp-social-sharing__overlay--active');
                   this.classList.toggle('cmp-social-sharing__title--active');
               });

               document.addEventListener('click', function (event) {
                   if (!socialWrapper.contains(event.target)) {
                       socialOverlay.classList.remove('cmp-social-sharing__overlay--active');
                       socialTitleButton.classList.remove('cmp-social-sharing__title--active');
                   }
               });
           }
        })
    });
}
