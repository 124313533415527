if (document.readyState !== 'loading') {
    handleDownload();
} else {
    document.addEventListener('DOMContentLoaded', function () {
        handleDownload();
    });
}

document.addEventListener('DownloadContentUpdated', function () {
    handleDownload();
});

function handleDownload() {
    const downloadsElements = document.querySelectorAll('.cmp-download');

    downloadsElements.forEach(function (downloadsElement) {
        const downloadTitle = downloadsElement.querySelector('.cmp-download__title');
        const downloadDescription = downloadsElement.querySelector('.cmp-download__description');
        const downloadType = downloadsElement.querySelector('.cmp-download__property--format .cmp-download__property-content');
        const downloadSize = downloadsElement.querySelector('.cmp-download__property--size .cmp-download__property-content');

        removeElementsByClassName('cmp-download__title-type', downloadsElement);
        removeElementsByClassName('cmp-download__pretitle', downloadsElement);
        removeElementsByClassName('cmp-download__title-size', downloadsElement);
        removeElementsByClassName('cmp-download__description--secondary', downloadsElement);

        if (downloadType && downloadTitle) {
            const downloadTypeText = downloadType.innerHTML;
            let downloadTypeFinalText = downloadTypeText;
            if (downloadTypeText.indexOf('/') > -1) {
                downloadTypeFinalText = downloadTypeText.split('/')[1];
            }
            const downloadTitleType = document.createElement('div');
            downloadTitleType.classList.add('cmp-download__title-type');
            downloadTitleType.innerHTML = downloadTypeFinalText;
            downloadTitle.appendChild(downloadTitleType);

            const downloadPretitle = document.createElement('div');
            downloadPretitle.classList.add('cmp-download__pretitle');
            downloadPretitle.innerHTML = `Download <span>${downloadTypeFinalText}</span>`;
            downloadsElement.prepend(downloadPretitle);
        }

        if (downloadSize && downloadTitle) {
            const downloadSizeText = downloadSize.innerHTML;
            const downloadTitleSize = document.createElement('div');
            downloadTitleSize.classList.add('cmp-download__title-size');
            downloadTitleSize.innerHTML = downloadSizeText;
            downloadTitle.appendChild(downloadTitleSize);
        }

        if (downloadDescription) {
            const downloadDescriptionText = document.createElement('div');
            downloadDescriptionText.classList.add('cmp-download__description--secondary');
            downloadDescriptionText.innerHTML = downloadDescription.innerHTML;
            downloadsElement.appendChild(downloadDescriptionText);
        } else {
            if (downloadTitle) {
                downloadTitle.classList.add('cmp-download__title--no-description');
            }
        }
    });
}

function removeElementsByClassName(className, parent = document){
    const elements = parent.getElementsByClassName(className);
    while(elements.length > 0){
        elements[0].parentNode.removeChild(elements[0]);
    }
}
