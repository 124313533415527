if (document.readyState !== 'loading') {
    handleTooltip();
} else {
    document.addEventListener('DOMContentLoaded', function () {
        handleTooltip();
    });
}

function handleTooltip() {
    const tooltips = document.querySelectorAll('[data-tooltip]');

    tooltips.forEach(function (tooltip) {
        const tooltipText = tooltip.getAttribute('data-tooltip');
        let tooltipElement;

        tooltip.addEventListener('mouseover', function () {
            tooltipElement = document.createElement('div');
            const elementLeft = tooltip.getBoundingClientRect().left;
            const elementTop = tooltip.getBoundingClientRect().top;
            const elementWidth = tooltip.getBoundingClientRect().width;
            tooltipElement.classList.add('v-tooltip');
            tooltipElement.innerText = tooltipText;
            tooltipElement.style.left = elementLeft + elementWidth / 2 + 'px';
            tooltipElement.style.top = (elementTop - 2) + 'px';
            document.body.appendChild(tooltipElement);
        });

        tooltip.addEventListener('mouseleave', function () {
            if (tooltipElement) {
                tooltipElement.remove();
            }
        });
    });
}
