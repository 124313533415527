if (document.readyState !== 'loading') {
    handleAccordion();
} else {
    document.addEventListener('DOMContentLoaded', function () {
        handleAccordion();
    });
}

function handleAccordion() {
    const accordions = document.querySelectorAll('.accordion');
    accordions.forEach(item => {
        const accordionButtons = item.querySelectorAll('.cmp-accordion__button');

        accordionButtons.forEach(button => {
            button.addEventListener('click', () => {
                const viewportWidth = window.innerWidth;

                if (viewportWidth < 1024) {
                    setTimeout(() => {
                        const parent = button.closest('.cmp-accordion__item');
                        const currentButton = parent.querySelector('.cmp-accordion__button');
                        if (currentButton.getAttribute('aria-expanded') === 'true') {
                            const scrollY = window.scrollY;
                            const buttonTop = button.getBoundingClientRect().top;
                            const offsetTop = buttonTop + scrollY;
                            const offset = scrollY > offsetTop ? 76 : 10;
                            window.scrollTo({
                                top: offsetTop - offset,
                                behavior: 'smooth'
                            });
                        }
                    }, 150);
                }
            });
        });
    });
}
