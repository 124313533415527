export function formatPublicationDate(publicationDate) {
    const publicationDateObject = new Date(publicationDate);
    const now = new Date();
    let dateString;

   if (now - publicationDateObject < 86400000) {
        dateString = `heute`;
    } else {
        const day = publicationDateObject.getDate();
        const month = publicationDateObject.getMonth() + 1;
        const year = publicationDateObject.getFullYear();
        dateString = `${day} / ${month} / ${year}`;
    }

    return dateString;
}
