if (document.readyState !== 'loading') {
    handleColumnContainer();
} else {
    document.addEventListener('DOMContentLoaded', function () {
        handleColumnContainer();
    });
}

function handleColumnContainer() {
    const percentages = [100, 50, 33, 25];
    const perRowMap = {100: 1, 50: 2, 33: 3, 25: 4};

    function computePeopleTeaserWidths() {
        const columnContainers = document.querySelectorAll('.columncontainer');

        columnContainers.forEach(function (columnContainer) {
            const cmpContainer = columnContainer.querySelector('.cmp-container');
            const peopleTeaser = cmpContainer ? cmpContainer.querySelector('.peopleteaser') : null;

            if (peopleTeaser) {
                const computedStyle = getComputedStyle(cmpContainer);
                const paddingLeft = parseFloat(computedStyle.paddingLeft);
                const paddingRight = parseFloat(computedStyle.paddingRight);

                const widthOfCmpContainer = cmpContainer.offsetWidth - paddingLeft - paddingRight;

                const peopleTeaserWidth = peopleTeaser.offsetWidth;
                const peoplePercentage = (peopleTeaserWidth / widthOfCmpContainer) * 100;

                let closest = percentages.reduce((a, b) => {
                    return Math.abs(b - peoplePercentage) < Math.abs(a - peoplePercentage) ? b : a;
                });

                let perRow = perRowMap[closest];
                const children = Array.from(cmpContainer.children).filter(child => !child.classList.contains('cmp-container--title') && !child.classList.contains('title'));

                if (children.length > 0) {
                    children.forEach(function (child) {
                        if (!child.classList.contains('cmp-container--title') && !child.classList.contains('title')) {
                            if (child.classList.contains('peopleteaser')) {
                                const cmpTeaserPosition = child.querySelector('.cmp-people-teaser__position');
                                const cmpTeaserName = child.querySelector('.cmp-people-teaser__name');
                                if (cmpTeaserPosition) {
                                    cmpTeaserPosition.style.height = 'auto';
                                }
                                if (cmpTeaserName) {
                                    cmpTeaserName.style.height = 'auto';
                                }
                            }
                        }
                    });

                    const groupedChildren = [];
                    for (let i = 0; i < children.length; i += perRow) {
                        groupedChildren.push(children.slice(i, i + perRow));
                    }

                    groupedChildren.forEach(function (group) {
                        let maxHeightPosition = 0;
                        let maxHeightName = 0;

                        group.forEach(function (child) {
                                if (child.classList.contains('peopleteaser')) {
                                    const cmpTeaserPosition = child.querySelector('.cmp-people-teaser__position');
                                    const cmpTeaserName = child.querySelector('.cmp-people-teaser__name');
                                    if (cmpTeaserPosition) {
                                        const positionHeight = cmpTeaserPosition.offsetHeight;
                                        const nameHeight = cmpTeaserName.offsetHeight;
                                        if (positionHeight > maxHeightPosition) {
                                            maxHeightPosition = positionHeight;
                                        }
                                        if (nameHeight > maxHeightName) {
                                            maxHeightName = nameHeight;
                                        }
                                    }
                                }
                        });

                        if (group.length > 1) {
                            group.forEach(function (child) {
                                    if (child.classList.contains('peopleteaser')) {
                                        const cmpTeaserPosition = child.querySelector('.cmp-people-teaser__position');
                                        const cmpTeaserName = child.querySelector('.cmp-people-teaser__name');
                                        if (cmpTeaserPosition) {
                                            cmpTeaserPosition.style.height = `${maxHeightPosition}px`;
                                        }
                                        if (cmpTeaserName) {
                                            cmpTeaserName.style.height = `${maxHeightName}px`;
                                        }
                                    }
                            });
                        }
                    });
                }
            }
        });
    }

    computePeopleTeaserWidths();
    window.addEventListener('resize', computePeopleTeaserWidths);
}
