if (document.readyState !== 'loading') {
    handleText();
} else {
    document.addEventListener('DOMContentLoaded', function () {
        handleText();
    });
}

function handleText() {
    const textElements = document.querySelectorAll('.cmp-text');

    textElements.forEach(function (textElement) {
        const tables = textElement.querySelectorAll('table');

        tables.forEach(function (table) {
            const tableWrapper = document.createElement('div');
            tableWrapper.classList.add('cmp-text__table-wrapper');
            table.parentNode.insertBefore(tableWrapper, table);
            tableWrapper.appendChild(table);
        });
    });
}
