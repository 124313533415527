// Create the "Back to Top" button
const backToTopButton = document.createElement("button");
backToTopButton.className = "back-to-top-button";
document.body.appendChild(backToTopButton);

// Show the button based on scroll position and device type
window.addEventListener("scroll", function () {
  let scrollPosition = document.body.scrollTop || document.documentElement.scrollTop;

  if (scrollPosition > 500) {
    backToTopButton.classList.add('active');
  } else {
    backToTopButton.classList.remove('active');
  }
});

// Scroll to the top of the document when the button is clicked
backToTopButton.addEventListener("click", function () {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
});
