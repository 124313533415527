import { truncateText } from '../../utils/truncate';
import { formatPublicationDate } from "../../utils/formatPublicationDate";

if (document.readyState !== 'loading') {
    handleTopNews();
} else {
    document.addEventListener('DOMContentLoaded', function () {
        handleTopNews();
    });
}

document.addEventListener('TopNewsContentUpdated', function () {
    handleTopNews();
});

function handleTopNews() {
    // Compact mode
    const topNewsSwitches = document.querySelectorAll('.js-top-news-switch');
    topNewsSwitches.forEach(function (checkbox) {
        const isReady = checkbox.classList.contains('is-ready');
        if (!isReady) {
            const topNewsParent = checkbox.closest('.cmp-top-news');
            checkbox.addEventListener('change', function () {
                if (checkbox.checked) {
                    topNewsParent.classList.add('cmp-top-news--compact');
                } else {
                    topNewsParent.classList.remove('cmp-top-news--compact');
                }
            });
            checkbox.classList.add('is-ready');
        }
    });

    // Loading articles
    const topNewsBlocks = document.querySelectorAll('.topNews-news .cmp-top-news');
    const getTopNews = async function (url) {
        try {
            const response = await fetch(url);
            return await response.json();
        } catch (error) {
            console.log(error);
        }
    }

    topNewsBlocks.forEach(function (topNewsBlock) {
        const isReady = topNewsBlock.classList.contains('cmp-top-news--is-ready');
        if (!isReady) {
            const dataUrl = topNewsBlock.getAttribute('data-top-news-endpoint');
            const dataLimit = topNewsBlock.getAttribute('data-limit');
            const articleList = topNewsBlock.querySelector('.cmp-top-news__article-list');
            const tagList = topNewsBlock.querySelector('.cmp-top-news__tags');
            let activeTags = [];
            let initialTags = [];
            const footerWrapper = topNewsBlock.querySelector('.cmp-top-news__footer');
            const footerLink = topNewsBlock.querySelector('.cmp-top-news__footer-link');

            if (tagList) {
                activeTags = Array.from(tagList.querySelectorAll('.v-filter-tags__item')).map(function (tag) {
                    return tag.innerText;
                });
                initialTags = [...activeTags];
            }

            if (footerLink) {
                footerLink.remove();
            }

            topNewsBlock.classList.add('cmp-top-news--loading');

            getTopNews(dataUrl).then(function (result) {
                renderArticles(result, articleList, activeTags, dataLimit);

                if (result.showMoreText && result.showMoreLink) {
                    footerWrapper.innerHTML = `
                    <a href="${result.showMoreLink}" class="v-button v-button--secondary v-button--icon-right v-button--icon-arrow-down cmp-top-news__footer-link">
                        <span class="v-button__icon"></span>
                        <span>${result.showMoreText}</span>
                    </a>
                `;
                }

                if (tagList && initialTags.length > 0) {
                    const showAllTag = document.createElement('button');
                    showAllTag.classList.add('cmp-top-news__tag');
                    showAllTag.classList.add('cmp-top-news__tag--show-all');
                    showAllTag.classList.add('v-filter-tags__item');
                    showAllTag.setAttribute('disabled', '');
                    showAllTag.innerHTML = `Alle
                    <span class="v-filter-tags__item-icon">
                        <span class="v-filter-tags__item-icon-inner"></span>
                    </span>`;
                    tagList.prepend(showAllTag);
                }

                // on click on tags show only articles with this tag
                const tags = tagList ? tagList.querySelectorAll('.v-filter-tags__item') : [];

                if (tags.length > 0) {
                    tags.forEach(function (tag) {
                        tag.addEventListener('click', function () {
                            const showAllTag = tagList.querySelector('.cmp-top-news__tag--show-all');
                            const tagText = tag.innerText;
                            const isActive = tag.classList.contains('v-filter-tags__item--active');
                            const isShowAll = tag.classList.contains('cmp-top-news__tag--show-all');
                            const allTagsSelected = arraysHaveSameItems(activeTags, initialTags);
                            let allTagsSelectedInHtml = true;

                            tags.forEach(function (tag) {
                                if (!tag.classList.contains('v-filter-tags__item--active') && !tag.classList.contains('cmp-top-news__tag--show-all')) {
                                    allTagsSelectedInHtml = false;
                                }
                            });

                            topNewsBlock.classList.add('cmp-top-news--loading');

                            if (isShowAll) {
                                activeTags.length = 0;
                                activeTags.push(...initialTags);
                                showAllTag.setAttribute('disabled', '');

                                tags.forEach(function (tag) {
                                    tag.classList.remove('v-filter-tags__item--active');
                                });
                            } else if (isActive && activeTags.length === 1) {
                                activeTags.length = 0;
                                activeTags.push(...initialTags);
                                showAllTag.setAttribute('disabled', '');

                                tags.forEach(function (tag) {
                                    tag.classList.remove('v-filter-tags__item--active');
                                });
                            } else if (allTagsSelected && !allTagsSelectedInHtml) {
                                showAllTag.removeAttribute('disabled');
                                activeTags.length = 0;
                                activeTags.push(tagText);

                                tags.forEach(function (tag) {
                                    const currentTagText = tag.innerText;
                                    if (activeTags.includes(currentTagText)) {
                                        tag.classList.add('v-filter-tags__item--active');
                                    } else {
                                        tag.classList.remove('v-filter-tags__item--active');
                                    }
                                });
                            } else if (allTagsSelected && allTagsSelectedInHtml) {
                                activeTags.splice(activeTags.indexOf(tagText), 1);

                                tags.forEach(function (tag) {
                                    const currentTagText = tag.innerText;
                                    if (activeTags.includes(currentTagText)) {
                                        tag.classList.add('v-filter-tags__item--active');
                                    } else {
                                        tag.classList.remove('v-filter-tags__item--active');
                                    }
                                });
                            } else {
                                showAllTag.removeAttribute('disabled');

                                if (isActive) {
                                    activeTags.splice(activeTags.indexOf(tagText), 1);
                                } else {
                                    activeTags.push(tagText);
                                }

                                tags.forEach(function (tag) {
                                    const currentTagText = tag.innerText;
                                    if (activeTags.includes(currentTagText)) {
                                        tag.classList.add('v-filter-tags__item--active');
                                    } else {
                                        tag.classList.remove('v-filter-tags__item--active');
                                    }
                                });
                            }

                            articleList.innerHTML = '';
                            renderArticles(result, articleList, activeTags, dataLimit);
                            topNewsBlock.classList.remove('cmp-top-news--loading');
                        });
                    });
                }

                topNewsBlock.classList.remove('cmp-top-news--loading');
            });
            topNewsBlock.classList.add('cmp-top-news--is-ready');
        }
    });

    function renderArticles(newsItems, articleList, filteredTags, limit) {
        if (limit <= 0) {
            return;
        }

        let isFirst = true;
        let count = 0;

        for (let i = 0; i < newsItems.items.length && count < limit; i++) {
            let newsItem = newsItems.items[i];

            if (filteredTags.length === 0 || (filteredTags && newsItem.tags && newsItem.tags.some(r => filteredTags.includes(r)))) {
                renderOneArticle(articleList, newsItem, isFirst);
                count++;

                if (isFirst) {
                    isFirst = false;
                }
            }
        }
    }

    function renderOneArticle(articleList, newsItem, isFirst) {
        const placeholderLink = '/content/dam/tennis/system-assets/images/no-img-placeholder.png';
        let dateString = '';
        if (newsItem.publicationDate) {
            dateString = formatPublicationDate(newsItem.publicationDate);
        }

        const article = document.createElement('div');
        article.classList.add('cmp-top-news__article');
        if (isFirst) {
            article.classList.add('cmp-top-news__article--main');
        }
        article.innerHTML = `
                    <div class="cmp-top-news__article-image-wrap">
                        <div class="cmp-top-news__article-image">
                            <a href="${newsItem.url}">
                                <div>
                                    <div class="cmp-image">
                                    ${newsItem.image && newsItem.image.src ? `
                                        <img
                                            src="${newsItem.image.src}"
                                            ${newsItem.image.srcset ? `srcset="${newsItem.image.srcset}"` : ''}
                                            alt="${newsItem.image.alt ? newsItem.image.alt : ''}"
                                            ${newsItem.image.copyright ? `data-copyright="${newsItem.image.copyright}"` : ''}
                                        >
                                         ` : `
                                        <img
                                            src="${placeholderLink}"
                                            alt="${newsItem.title}"
                                            ${newsItem.image.copyright ? `data-copyright="${newsItem.image.copyright}"` : ''}
                                        >
                                    `}
                                    ${newsItem.image.copyright ? `<div class="cmp-image__copyright">&#169 ${newsItem.image.copyright}</div>` : ''}
                                    
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="cmp-top-news__article-image-footer">
                            <div class="cmp-top-news__article-image-info">
                                ${newsItem.image.caption ? `${newsItem.image.caption}<br>` : ''}
                                ${newsItem.image.copyright}
                            </div>
                            ${newsItem.tags ? `
                                <div class="cmp-top-news__article-category-secondary">
                                    <span>
                                        ${newsItem.tags[0]}
                                    </span>
                                </div>`
                : ''}
                        </div>
                    </div>
                    <div class="cmp-top-news__article-content">
                        ${newsItem.publicationDate ? `
                            <div class="cmp-top-news__article-length">
                                ${dateString}
                            </div>
                        ` : ''}
                        <h3 class="cmp-top-news__article-title v-title v-title--h6">
                            <a href="${newsItem.url}">${newsItem.title}</a>
                        </h3>
                        <div class="cmp-top-news__article-text">
                            ${newsItem.text ? truncateText(newsItem.text, 260) : ''}
                        </div>
                        <div class="cmp-top-news__article-text-mobile">
                            ${newsItem.text ? truncateText(newsItem.text, 100) : ''}
                        </div>
                        ${newsItem.tags ? `
                            <div class="cmp-top-news__article-category">
                                <span>
                                    ${newsItem.tags[0]}
                                </span>
                            </div>
                        ` : ''}
                    </div>`;
        articleList.appendChild(article);
    }

    function arraysHaveSameItems(array1, array2) {
        if (array1.length !== array2.length) {
            return false;
        }
        return array1.every(item => array2.includes(item));
    }
}
