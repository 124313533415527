const getRandomNumber = () => {
  const randomDecimal = Math.random();
  const randomNumber = Math.floor(randomDecimal * 3) + 1;
  return randomNumber;
};

// Example usage:
const randomIndex = getRandomNumber();

const elementToHighlight = document.querySelector(`.ad .ad-medium-rectangle > div:nth-of-type(${randomIndex})`);
if (elementToHighlight) elementToHighlight.style.display = 'block';
