if (document.readyState !== 'loading') {
    handleStageVideo();
} else {
    document.addEventListener('DOMContentLoaded', function () {
        handleStageVideo();
    });
}

document.addEventListener('StageVideoContentUpdated', function () {
    handleStageVideo();
});

function handleStageVideo() {
    const stageVideos = document.querySelectorAll('.dynamicmedia--stage');
    const isAuthorMode = document.getElementById('meta-wcmmode'); // Hotfix for AEM viewport height bug

    stageVideos.forEach(function (stageVideo) {
        const videoLinks = stageVideo.querySelectorAll('.dynamicmedia__action-link');

        videoLinks.forEach(function (videoLink) {
            if (videoLink.firstChild.nodeType === 3) {
                const span = document.createElement('span');
                while (videoLink.firstChild) {
                    span.appendChild(videoLink.firstChild)
                }
                videoLink.appendChild(span);
            }
        });

        if (!isAuthorMode) {
            setStageVideoHeight();
            window.addEventListener('resize', setStageVideoHeight);
        }

        function setStageVideoHeight() {
            const header = document.querySelector('.header');

            if (header) {
                const teaserHeight = window.innerHeight - header.offsetHeight;
                stageVideo.style.height = `${teaserHeight}px`;
            }
        }
    });
}
