if (document.readyState !== 'loading') {
    handlePresenter();
} else {
    document.addEventListener('DOMContentLoaded', function () {
        handlePresenter();
    });
}

document.addEventListener('TopNewsContentUpdated', function () {
    handlePresenter();
});

function handlePresenter() {
    const presenters = document.querySelectorAll('.presenter');
    presenters.forEach(item => {
        const innerDiv = item.querySelector('div');

        if (innerDiv) {
            const dataImage = innerDiv.getAttribute('data-image');
            const dataText = innerDiv.getAttribute('data-text');
            const dataLink = innerDiv.getAttribute('data-link');

            if (dataLink && dataImage && dataText) {
                innerDiv.innerHTML = `
                <div class="cmp-presenter">
                    ${dataText ? `<div class="cmp-presenter__text">${dataText}</div>` : ''}
                    ${dataLink && dataImage ? `
                        <div class="cmp-presenter__image">
                            <a href="${dataLink}" target="_blank">
                                <img src="${dataImage}" alt="${dataText}">
                            </a>
                        </div>
                    ` : ''}
                </div>
                `;
            }
        }
    });
}
