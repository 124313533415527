import {formatPublicationDate} from "../../utils/formatPublicationDate";

if (document.readyState !== 'loading') {
    handleArticleTemplate();
} else {
    document.addEventListener('DOMContentLoaded', function () {
        handleArticleTemplate();
    });
}

function handleArticleTemplate() {
    if (document.body.classList.contains('article-page')) {
        const mainTitleWrapper = document.querySelector('.cmp-title');
        const mainTag = document.querySelector('.cmp-article-properties__main-tag');
        const publicationDate = document.querySelector('.cmp-article-properties__publication-date');
        const mainImage = document.querySelector('.cmp-image__image');
        let dateString = '';
        let mainTagString = '';

        const containers = document.querySelectorAll('.root > .cmp-container > .container');
        if (containers.length >= 1) {
            containers[0].classList.add('container--article-first');
        }
        if (containers.length >= 2) {
            containers[1].classList.add('container--article-second');
        }

        if (publicationDate) {
            const publicationDateValue = publicationDate.getAttribute('data-publicationdate');
            dateString = formatPublicationDate(publicationDateValue);
        }

        if (mainTag) {
            mainTagString = mainTag.textContent;
        }

        if (mainTitleWrapper) {
            let articleInfoDiv = document.createElement('div');
            articleInfoDiv.classList.add('cmp-title__article-info');
            let articleInfoString = '';
            if (mainTagString) {
                articleInfoString += `<div class="cmp-title__article-tag">${mainTagString}</div>`;
            }
            if (dateString) {
                articleInfoString += `<div class="cmp-title__article-date">${dateString}</div>`;
            }
            articleInfoDiv.innerHTML = articleInfoString;
            mainTitleWrapper.prepend(articleInfoDiv);
        }

        if (mainImage) {
            const copyright = mainImage.getAttribute('data-copyright');
            const caption = mainImage.getAttribute('data-caption');
            if (caption || copyright) {
                const imageMoreInfoString = `<div class="cmp-image__more-info">
                    <div class="cmp-image__caption">${caption ?? ''}</div>
                    <div class="cmp-image__copy">${copyright ?? ''}</div>
                </div>`;
                mainImage.insertAdjacentHTML('afterend', imageMoreInfoString);
            }
        }
    }
}
