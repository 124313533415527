if (document.readyState !== 'loading') {
    handleOverviewTemplate();
} else {
    document.addEventListener('DOMContentLoaded', function () {
        handleOverviewTemplate();
    });
}

function handleOverviewTemplate() {
    if (document.body.classList.contains('overview-page')) {
        const mainBreadcrumbs = document.querySelector('.cmp-breadcrumb');
        const mainImage = document.querySelector('.cmp-image__image');

        const containers = document.querySelectorAll('.root > .cmp-container > .container');
        if (containers.length >= 1) {
            containers[0].classList.add('container--overview-first');
        }
        if (containers.length >= 2) {
            containers[1].classList.add('container--overview-second');
        }

        resizeBreadcrumbs();
        window.addEventListener('resize', function () {
            resizeBreadcrumbs();
        });

        function resizeBreadcrumbs() {
            if (mainBreadcrumbs && mainImage) {
                const mainImageHeight = mainImage.offsetHeight;
                const mainBreadcrumbsHeight = mainBreadcrumbs.offsetHeight;

                mainBreadcrumbs.style.top = `${mainImageHeight - mainBreadcrumbsHeight}px`;
            }
        }
    }
}
