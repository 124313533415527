if (document.readyState !== 'loading') {
    handleVideo();
} else {
    document.addEventListener('DOMContentLoaded', function () {
        handleVideo();
    });
}

document.addEventListener('VideoContentUpdated', function () {
    handleVideo();
});

function handleVideo() {
    const videos = document.querySelectorAll('.dynamicmedia:not(.dynamicmedia--stage)');
    const globalCursor = document.querySelector('.global-cursor');

    videos.forEach(function (video) {
        const videoPlayer = video.querySelector('.video');
        const videoElement = video.querySelector('video');

        if (videoElement) {
            initVideo(videoPlayer, videoElement);
        } else {
            const observer = new MutationObserver(function(mutationsList) {
                mutationsList.forEach(function(mutation) {
                    if (mutation.type === 'childList') {
                        const insideVideos = Array.from(mutation.addedNodes).filter(function(node) {
                            return node.nodeName === 'VIDEO';
                        });
                        insideVideos.forEach(function(insideVideo) {
                            if (!insideVideo.dataset.processed) {
                                insideVideo.dataset.processed = true;
                                initVideo(videoPlayer, insideVideos[0]);
                            }
                        });
                    }
                });
            });

            if (videoPlayer) {
                observer.observe(videoPlayer, {childList: true, subtree: true});
            }
        }
    });

    function initVideo(videoPlayer, videoElement) {
        const videoPlayerWrapper = videoPlayer.querySelector('.s7videoplayer');
        let videoState = 'play'
        const isTouch = videoPlayer.classList.contains('s7touchinput');
        const volumeControl = videoPlayer.querySelector('.s7mutablevolume');
        const volumeControlHidden = volumeControl ? window.getComputedStyle(volumeControl).display === 'none' : false;

        if (videoPlayer && volumeControlHidden) {
            videoPlayer.classList.add('volume-control-hidden');
        }

        if (!isTouch) {
            videoPlayerWrapper.addEventListener('mouseover', () => {
                globalCursor.innerText = videoState;
                globalCursor.classList.add('active');
            });
            videoPlayerWrapper.addEventListener('mouseleave', () => {
                globalCursor.classList.remove('active');
            });

            // on video play or pause change videoState
            videoElement.addEventListener('play', () => {
                videoState = 'stop';
                globalCursor.innerText = videoState;
            });

            videoElement.addEventListener('pause', () => {
                videoState = 'play';
                globalCursor.innerText = videoState;
            });
        }
    }
}
